:root {
    --ratio: (100vw / 1440);
    --safe-area-x: calc(var(--ratio) * 118);
    --safe-area-x-wrapper: 24px;
    --safe-area-x-content: calc(var(--safe-area-x) - var(--safe-area-x-wrapper));
    
    @media screen and (max-width: 1024px) {
        --ratio: 1px;
        --safe-area-x: 24px;
        --safe-area-x-wrapper: 8px;
    }

}

.spotlight {

    /* init */
    --spot-color: rgba(255, 240, 200, 1);
    --spot-bg-color: white;
    background: radial-gradient(circle,
            var(--spot-color) 5%,
            var(--spot-bg-color) 20%);
    -webkit-text-fill-color: transparent;
    background-size: 200% 200%;
    -webkit-background-clip: text;
    background-repeat: none;

    /* animation */
    animation-name: move;
    animation-duration: 8s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
}

@keyframes move {
    0% {
        background-position: 125% 50%;
    }

    100% {
        background-position: -0% 50%;
    }
}
