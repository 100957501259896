@font-face {
  font-family: InterBlank;
  src: url(./assets/font/Inter-Black.ttf);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
}

@font-face {
  font-family: InterBold;
  src: url(./assets/font/Inter-Bold.ttf);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
}

@font-face {
  font-family: InterExtraBold;
  src: url(./assets/font/Inter-ExtraBold.ttf);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
}

@font-face {
  font-family: InterExtraLight;
  src: url(./assets/font/Inter-ExtraLight.ttf);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
}

@font-face {
  font-family: InterLight;
  src: url(./assets/font/Inter-Light.ttf);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
}

@font-face {
  font-family: InterMedium;
  src: url(./assets/font/Inter-Medium.ttf);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
}

@font-face {
  font-family: InterRegular;
  src: url(./assets/font/Inter-Regular.ttf);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
}

@font-face {
  font-family: InterSemiBold;
  src: url(./assets/font/Inter-SemiBold.ttf);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
}

@font-face {
  font-family: InterThin;
  src: url(./assets/font/Inter-Thin.ttf);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
}

/* Roobert Font */
@font-face {
  font-family: RoobertBold;
  src: url(./assets/font/Roobert-Bold.ttf);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
}

@font-face {
  font-family: RoobertBoldItalic;
  src: url(./assets/font/Roobert-BoldItalic.ttf);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
}

@font-face {
  font-family: RoobertHeavy;
  src: url(./assets/font/Roobert-Heavy.ttf);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
}

@font-face {
  font-family: RoobertHeavyItalic;
  src: url(./assets/font/Roobert-HeavyItalic.ttf);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
}

@font-face {
  font-family: RoobertLight;
  src: url(./assets/font/Roobert-Light.ttf);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
}

@font-face {
  font-family: RoobertLightItalic;
  src: url(./assets/font/Roobert-LightItalic.ttf);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
}

@font-face {
  font-family: RoobertMedium;
  src: url(./assets/font/Roobert-Medium.ttf);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
}

@font-face {
  font-family: RoobertMediumItalic;
  src: url(./assets/font/Roobert-MediumItalic.ttf);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
}

@font-face {
  font-family: RoobertRegular;
  src: url(./assets/font/Roobert-Regular.ttf);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
}

@font-face {
  font-family: RoobertRegularItalic;
  src: url(./assets/font/Roobert-RegularItalic.ttf);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
}

@font-face {
  font-family: RoobertSemiBold;
  src: url(./assets/font/Roobert-SemiBold.ttf);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
}

@font-face {
  font-family: RoobertSemiBoldItalic;
  src: url(./assets/font/Roobert-SemiBoldItalic.ttf);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
}

html, body, #root{
  background-color: #0A0A0A;
}

body {
  font-family: RoobertRegular Helvetica Arial Tahoma;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  font-size: 14px;
  line-height: 1;

  --wcm-z-index: 100 !important;
}